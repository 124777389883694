<template>
  <div>
    <Headers :viewParams="viewParams" />
    <div class="content-page super-admin2">
      <div class="content">
        <div class="col-12 ">
          <div class="card-box" v-if="can_show == 1">
            <div class="page-title-box">
              <h4 class="page-title">
                <div class="lang" v-html="lan.creis_use_total"></div>
              </h4><button class="submin">Google <div class="lang" v-html="lan.analytics"></div></button>
            </div>

            <ul class="tab-head nav nav-tabs">
              <li class="nav-item active" data-id="tc1">
                <a href="#tc1" data-toggle="tab" aria-expanded="true" class="nav-link active"
                  @click="loadlog('access_front_page')">{{ lan.access_front_page }}</a>
              </li>
              <li class="nav-item" data-id="tc2">
                <a href="#tc2" data-toggle="tab" aria-expanded="false" class="nav-link" @click="loadlog('access')">{{
                  lan.access }}</a>
              </li>
            </ul>
            <div class="row">
              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                  <div class="row">
                    <div class="col-6">
                      <div class="avatar-lg rounded-circle bg-soft-primary border-primary border">
                        <i class="fe-heart font-22 avatar-title text-primary"></i>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-right">
                        <h3 class="text-dark mt-1"><span data-plugin="counterup">{{
                          formart_money_int(common_page_data.pageviews) }}</span>
                          <div class="lang" v-html="lan.analytics_1"></div>
                        </h3>
                        <p class="text-muted mb-1 text-truncate">ページビュー数</p>
                      </div>
                    </div>
                  </div> <!-- end row-->
                </div> <!-- end widget-rounded-circle-->
              </div> <!-- end col-->

              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                  <div class="row">
                    <div class="col-6">
                      <div class="avatar-lg rounded-circle bg-soft-success border-success border">
                        <i class="fe-shopping-cart font-22 avatar-title text-success"></i>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-right">
                        <h3 class="text-dark mt-1"><span data-plugin="counterup">{{
                          formart_money_int(common_page_data.Num_of_Clients) }}</span>／{{
                              formart_money_int(common_page_data.total_company) }}
                          <div class="lang" v-html="lan.analytics_2"></div>
                        </h3>
                        <p class="text-muted mb-1 text-truncate">利用された会社数</p>
                      </div>
                    </div>
                  </div> <!-- end row-->
                </div> <!-- end widget-rounded-circle-->
              </div> <!-- end col-->

              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                  <div class="row">
                    <div class="col-6">
                      <div class="avatar-lg rounded-circle bg-soft-info border-info border">
                        <i class="fe-bar-chart-line- font-22 avatar-title text-info"></i>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-right">
                        <h3 class="text-dark mt-1"><span data-plugin="counterup">{{
                          formart_money_int(common_page_data.active_users) }}</span>
                          <div class="lang" v-html="lan.analytics_3"></div>
                        </h3>
                        <p class="text-muted mb-1 text-truncate">アクティブ外部ユーザ数</p>
                      </div>
                    </div>
                  </div> <!-- end row-->
                </div> <!-- end widget-rounded-circle-->
              </div> <!-- end col-->

              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                  <div class="row">
                    <div class="col-6">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="fe-eye font-22 avatar-title text-warning"></i>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-right">
                        <h3 class="text-dark mt-1"><span data-plugin="counterup">{{
                          common_page_data.average_pageviews.toFixed(2) }}</span>
                          <div class="lang" v-html="lan.analytics_4"></div>
                        </h3>
                        <p class="text-muted mb-1 text-truncate">平均閲覧ページ数</p>
                      </div>
                    </div>
                  </div> <!-- end row-->
                </div> <!-- end widget-rounded-circle-->
              </div> <!-- end col-->
            </div>
            <div class="row vertical-text-chart">
              <div class="col-xl-12">
                <div class="card-box pb-2">
                  <h4 class="header-title mb-3">日次アクセス報告</h4>
                  <div class="">
                    <div><input class="form-control mr-1 w-80 useremail" type="search" placeholder="Email"
                        aria-label="Search" v-model="useremail" @keyup.enter="search('user_daily_date')">
                      <div class="lang" v-html="lan.create_date"></div>
                      <ndatepicker :ndate="'user_daily_date'" :placeholder="'2022-01'" :types="'month'"
                        :format="'yyyy-MM'" :val="user_daily_date" @changedate="changedate" /> to
                      <ndatepicker :ndate="'user_daily_date_to'" :placeholder="'2022-01'" :types="'month'"
                        :format="'yyyy-MM'" :val="user_daily_date_to" @changedate="changedate" /><button type="button"
                        class="btn btn-white ml-2" @click="search('user_daily_date')"><i class="fas fa-search"
                          aria-hidden="true"></i></button>
                    </div>
                    <apexchart id="user_daily_chart" v-if="user_daily_chart_options != null"
                      :options="user_daily_chart_options" :series="user_daily_chart_options.series"></apexchart>
                  </div>
                </div>
              </div>

              <div class="col-xl-12">
                <div class="card-box pb-2">
                  <h4 class="header-title mb-3">ユーザ関心エリア</h4>
                  <div class="">
                    <div>
                      <div class="lang" v-html="lan.create_date"></div>
                      <ndatepicker :ndate="'area_total_date'" :types="'month'" :placeholder="'2022-01'"
                        :format="'yyyy-MM'" :val="area_total_date" @changedate="changedate" /> to
                      <ndatepicker :ndate="'area_total_date_to'" :placeholder="'2022-01'" :types="'month'"
                        :format="'yyyy-MM'" :val="area_total_date_to" @changedate="changedate" /> <select
                        class="form-control ml-2 w250" :placeholder="lan.service" v-model="service">
                        <option value="service1">
                          <div class="lang" v-html="lan.service1"></div>
                        </option>
                        <option value="service2">
                          <div class="lang" v-html="lan.service2"></div>
                        </option>
                        <option value="service3">
                          <div class="lang" v-html="lan.service3"></div>
                        </option>
                      </select><button type="button" class="btn btn-white ml-2" @click="search('area_total_date')">
                        <i class="fas fa-search" aria-hidden="true"></i>
                      </button>
                    </div>
                    <apexchart id="area_total_chart" v-if="area_total_chart_options != null"
                      :options="area_total_chart_options" :series="area_total_chart_options.series"></apexchart>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card-box pb-2">

                  <h4 class="header-title mb-3">会社別の利用状態</h4>
                  <div class="">
                    <div><input class="form-control mr-1 w-80 useremail" type="search" placeholder="会社名"
                        aria-label="Search" v-model="cname" @keyup.enter="search('company_total_date')"><select
                        class="form-control ml-2 w250" :placeholder="lan.data_lable" v-model="type">
                        <option value="2">
                          <div class="lang" v-html="lan.access"></div>
                        </option>
                        <option value="1">
                          <div class="lang" v-html="lan.data_download"></div>
                        </option>

                      </select>
                      <div class="lang" v-html="lan.create_date"></div>
                      <ndatepicker :ndate="'company_total_date'" :types="'month'" :placeholder="'2022-01'"
                        :format="'yyyy-MM'" :val="company_total_date" @changedate="changedate" /> to
                      <ndatepicker :ndate="'company_total_date_to'" :placeholder="'2022-01'" :types="'month'"
                        :format="'yyyy-MM'" :val="company_total_date_to" @changedate="changedate" /> <button
                        type="button" class="btn btn-white ml-2" @click="search('company_total_date')">
                        <i class="fas fa-search" aria-hidden="true"></i>
                      </button>
                    </div>
                    <apexchart v-if="company_total_chart_options != null" id="company_total_chart"
                      :options="company_total_chart_options" :series="company_total_chart_options.series"></apexchart>
                  </div>

                </div>
              </div>
            </div>
            <div v-if="isPopupHelp" class="modal popup-modal">
              <div class="modal-dialog">
                <div class="popup modal-content help-box">
                  <div class="modal-header">
                    <button type="button" class="close" @click="isPopupHelp = false">
                      <span>×</span>
                    </button>
                  </div>
                  <div class="modal-body">

                    <h4 class="header-title mb-3">ユーザー別の利用状態</h4>
                    <div class="">
                      <apexchart v-if="user_total_chart_options != null" id="user_total_chart"
                        :options="user_total_chart_options" :series="user_total_chart_options.series"></apexchart>
                    </div>
                  </div>
                </div>
              </div>
              <div class="popup-bg" @click="isPopupHelp = false"></div>
            </div>

          </div>
          <div class="card-box" style="text-align:center" v-if="can_show == 0">
            <div class="lang" v-html="lan.file_not_permession"></div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Headers from '@/components/Headers.vue'
import Footer from '@/components/Footer.vue'
import Ndatepicker from '@/components/Ndatepicker.vue'
import { inject } from 'vue';
import axios from 'axios';
export default {
  name: 'commonset',
  components: {
    Headers, Footer, Ndatepicker,
  },
  data() {
    return {
      isstage: 0,
      cur_step: 1,
      log_type: 'access_front_page',
      lan: inject('global').creis_language_data,
      cur_lan: localStorage.getItem('cur_lan') != "en" ? 'jp' : "en",
      can_show: 0,
      common_date: {},
      common_page_data: {
        pageviews: 0,
        Num_of_Clients: 0,
        total_company: 0,
        active_users: 0,
        average_pageviews: 0
      },
      company_total_chart_options: null,
      user_total_chart_options: null,
      user_daily_chart_options: null,
      area_total_chart_options: null,
      useremail: "",
      service: "service1",
      cname: "",
      com_name: "",
      type: 2,
      isPopupHelp: false,
      isLoading: false,
      user_daily_date: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
      user_total_date: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
      user_daily_date_to: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
      user_total_date_to: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
      company_total_date: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
      company_total_date_to: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
      area_total_date: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
      area_total_date_to: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
    }
  },

  mounted() {
    this.partform = location.href.indexOf("cbre2.creis.jp") > -1 ? 'stage' : 'released';
    this.can_show = localStorage.getItem('groups') != "admin" ? 0 : 1;
    this.cur_lan = localStorage.getItem('cur_lan') != "en" ? "jp" : "en";

    let creis_language_data = JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan = creis_language_data['menu_data_' + this.cur_lan];
    this.loadlog('access_front_page');
  },

  methods: {
    loadlog: function (type) {
      this.log_type = type;

      this.getReport('user_daily_date', this);
      this.getReport('area_total_date', this);
      this.getReport('company_total_date', this);
      this.getReport('company_total_date', this);
      this.get_page_total();
    },
    changedate: function (e, id) {
      if (id == 'user_daily_date') {
        this.user_daily_date = e;
      } else if (id == 'user_total_date') {
        this.user_total_date = e;
      } else if (id == 'company_total_date') {
        this.company_total_date = e;
      } else if (id == 'area_total_date') {
        this.area_total_date = e;
      } else if (id == 'user_total_date_to') {
        this.user_total_date_to = e;
      } else if (id == 'company_total_date_to') {
        this.company_total_date_to = e;
      } else if (id == 'area_total_date_to') {
        this.area_total_date_to = e;
      } else if (id == 'user_daily_date_top') {
        this.area_total_date_to = e;
      }
    },
    search: function (id) {
      this.getReport(id, this);
    },
    get_page_total: function () {
      let that = this;
      axios.get('get_page_total', { params: { 'log_type': that.log_type } }).then(function (response) {
        if (response.data.status) {
          that.common_page_data = response.data.data;
        }
      });
    },
    async getReport(id, that) {
      console.log(id)
      that.isLoading = true;
      if (id == "company_total_date") {
        let response = await axios.get('get_total_company', {
          params: {
            'cname': that.cname,
            'type': that.type, //'2:access,1:download
            'log_type': that.log_type,
            'year_month': that.company_total_date == null ? '' : that.company_total_date.year + "-" + (that.company_total_date.month + 1 > 9 ? that.company_total_date.month + 1 : '0' + (that.company_total_date.month + 1)),
            'year_month_end': that.company_total_date_to == null ? '' : that.company_total_date_to.year + "-" + (that.company_total_date_to.month + 1 > 9 ? that.company_total_date_to.month + 1 : '0' + (that.company_total_date_to.month + 1))
          }
        });
        that.isLoading = false;
        if (response.data.status) {
          that.company_total_chart_options = that.get_options('Access by company', response.data.data.company, that, 'company_total_chart', response.data.data.count);

          that.error_msg = "";
        } else {
          that.suc_msg = "";
          that.error_msg = response.data.msg;
        }

      } else if (id == "user_total_date") {

        let response = await axios.get('get_total_user', {
          params: {
            'year_month': that.company_total_date == null ? '' : that.company_total_date.year + "-" + (that.company_total_date.month + 1 > 9 ? that.company_total_date.month + 1 : '0' + (that.company_total_date.month + 1)),
            'year_month_end': that.company_total_date_to == null ? '' : that.company_total_date_to.year + "-" + (that.company_total_date_to.month + 1 > 9 ? that.company_total_date_to.month + 1 : '0' + (that.company_total_date_to.month + 1)),
            'com_name': that.com_name,
            'type': that.type, //'2:access,1:download
            'log_type': that.log_type,
          }
        });
        that.isLoading = false;
        if (response.data.status) {
          that.user_total_chart_options = that.get_options('Access by user', response.data.data.username, that, 'user_total_chart', response.data.data.count);
          that.error_msg = "";
        } else {
          that.suc_msg = "";
          that.error_msg = response.data.msg;
        }

      } else if (id == "area_total_date") {

        let response = await axios.get('get_total_area', {
          params: {
            'year_month': that.area_total_date == null ? '' : that.area_total_date.year + "-" + (that.area_total_date.month + 1 > 9 ? that.area_total_date.month + 1 : '0' + (that.area_total_date.month + 1)),
            'year_month_end': that.area_total_date_to == null ? '' : that.area_total_date_to.year + "-" + (that.area_total_date_to.month + 1 > 9 ? that.area_total_date_to.month + 1 : '0' + (that.area_total_date_to.month + 1)),
            'service': that.service,
            'log_type': that.log_type,
          }
        });
        that.isLoading = false;
        if (response.data.status) {
          that.area_total_chart_options = that.get_options('Access by area', response.data.data.area, that, 'area_total_chart', response.data.data.count);
          that.error_msg = "";
        } else {
          that.suc_msg = "";
          that.error_msg = response.data.msg;
        }

      } else if (id == "user_daily_date") {

        let response = await axios.get('get_daily_user', {
          params: {
            'year_month': that.user_daily_date == null ? '' : that.user_daily_date.year + "-" + (that.user_daily_date.month + 1 > 9 ? that.user_daily_date.month + 1 : '0' + (that.user_daily_date.month + 1)),
            'year_month_end': that.user_daily_date_to == null ? '' : that.user_daily_date_to.year + "-" + (that.user_daily_date_to.month + 1 > 9 ? that.user_daily_date_to.month + 1 : '0' + (that.user_daily_date_to.month + 1)),
            'email': that.useremail,
            'log_type': that.log_type,
          }
        });
        that.isLoading = false;
        console.log(response.data)
        if (response.data.status) {
          that.user_daily_chart_options = that.get_options('Access by daily user', response.data.data.days, that, 'user_daily_chart', response.data.data.count);
          that.error_msg = "";
        } else {
          that.suc_msg = "";
          that.error_msg = response.data.msg;
        }
      }

    },
    formart_money_int: function (num) {

      if (isNaN(num) || num == null) {
        return num == '' || num == null ? '-' : num;
      }
      var result = '', counter = 0;
      //if(parseInt(num) == num) return num;
      num = num.toString();
      var numa = num.split('.');
      num = numa[0]
      for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i != 0 && num.charAt(i - 1) != '-') { result = ',' + result; }
      }
      return result;
    },
    get_options(name, headers, that, id, data) {
      return {
        chart: {
          events: {
            dataPointSelection: function (event, chartContext, config) {
              console.log('log', config);
              if (id == 'company_total_chart') {
                that.com_name = headers[config.dataPointIndex];
                that.isPopupHelp = true;
                that.getReport('user_total_date', that);
              }
            }
          },
          id: id,
          type: 'bar',
          stacked: true,
          height: 650,
          width: '100%',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
          },

        },
        forecastDataPoints: {
          count: headers.length,
          //fillOpacity: 0.3,
          strokeWidth: 5,
          dashArray: 5,
        },

        yaxis: {
          min: function (min) { return min < -1000 ? (Math.floor(min / 1000)) * 1000 : Math.floor(min / 10) * 10 },
          //tickAmount:2,
          max: function (max) { return max > 1000 ? (Math.ceil(max / 1000)) * 1000 : Math.ceil(max / 10) * 10 },
          labels: {
            formatter: function (value) {
              return that.formart_money_int(value);
            }
          },
        },
        colors: ['#80BBAD'],
        annotations: {
          xaxis: [
            {
              //   x: this.dateformat("start_out_quarter"),
              //   x2: this.dateformat("end_quarter"),
              reversed: true,
              fillColor: '#80BBAD',
              opacity: 1,

              label: {
                borderColor: '#80BBAD',
                style: {
                  fontSize: '12px',
                  color: '#fff',
                  height: '200px',
                  background: '#80BBAD',
                },
                offsetY: -10,
                text: '',
              }
            }]
        },

        title: {
          text: name,
          align: 'left'
        },
        // grid: {
        // //   row: {
        // //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        // //     opacity: 0.5
        // //   },
        // },
        dataLabels: {
          enabled: false, // 关闭默认的数据标签
          x: -10, // 调整数据标签的水平位置
          y: 10, // 调整数据标签的垂直位置
          rotate: -90, // 将数据标签旋转-90度，使其竖直显示
          formatter: function (val) {
            return val; // 返回原始值作为数据标签的内容
          },
          style: {
            fontSize: '14px',
          }
        },
        xaxis: {
          //reversed: true,
          title: {
            text: ""
          },
          categories: headers,//headers.map(function (item) { return item.substr(0,10); }),
          labels: {
            rotate: id == 'company_total_chart' ? -270:-90, // 设置为 0 使标签文字水平显示
            //verticalAlign: 'top',
            // 设置文本水平对齐方式
            //align: 'right',
            // 如果需要，可以设置文本宽度，确保完全显示
            width: 120,
            height:120,
            overflow: 'hidden'
          },
          //offsetY: 100,

        },
        series: [{ name: "Access", data: data }]
      }

    },
    get_series: function (data) {
      return [{ name: "Access", data: data }];
    },

  },
  watch: {
    $route() {
      this.cur_lan = localStorage.getItem('cur_lan') != "en" ? "jp" : "en";
      let creis_language_data = JSON.parse(localStorage.getItem('creis_language_data'));
      this.lan = creis_language_data['menu_data_' + this.cur_lan]

    }
  },
}
</script>
<style lang="scss" scoped>
.nav-tabs {
  margin-top: 20px;
}
.card-box{overflow: hidden;}
.super-admin2 {
  .apbox {
    width: 100%;
    display: table;
  }

  .line {
    width: 96% !important;
    text-align: left;
    padding: 2%;
    float: left;
    display: table;
  }

  .btn2 {
    margin: 10px auto;
    ;
    width: 70%;
    height: 35px;
    line-height: 35px;
    background: #778F9C;
    color: #fff;
    border: 0;
  }

  .result {
    clear: both;
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }

  .body {
    width: 1000px;
    margin: 30px auto;
  }

  .line .left-box {
    font-weight: bolder;
  }

  .left-box {
    width: 170px;
    float: left;
  }

  .title,
  h4 {
    color: #003f2d;
  }

  .right-box {
    margin-left: 3%;
    width: 60%;
    float: left;
  }

  .submin {
    color: #fff;
    background: #042a2d;
    font-size: 14px;
    width: 200px;
    border: 0;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    height: 30px;
    margin-left: 10px;
  }

  .btn-bottom {
    margin: 50px auto 0 auto;
  }

  .steps {
    margin-bottom: 30px;
  }

  .card-box {
    display: table;
    width: 100%;
  }
}

.vertical-text {
  writing-mode: vertical-lr;
  /* 竖直文本 */
  text-orientation: mixed;
  /* 混合文本方向 */
}

.useremail {
  width: 200px;
  float: left;
}

.w250 {
  width: 250px;
  float: left;
  margin-right: 15px;
}

.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 9;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  min-height: 750px;
  width: 850px;
  position: fixed;

  transform: translateX(-50%) !important;
  z-index: 16;
}

.apexcharts-xaxis-label {
  writing-mode: vertical-rl !important;
  text-orientation: upright !important;
  white-space: nowrap !important;
}
</style>